
import {ref, watch} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import useAlert from "@/composables/Alert.ts";
import {hideModal} from '@/core/helpers/dom';
import {consultorDelete} from "@/services/CrudService";

export default {
  name: "deletarConsultorModal",

  props: {
    consultor: {
      type: Object,
      required: true,
    }
  },
  emits: ["atualizar-consultores"],

  setup(props, { emit }) {
    const logs: any = ref({});
    const {showTimeAlert} = useAlert();
    let animate = ref(false);


    watch(() => props.consultor, () => {
      logs.value = props.consultor
    });

    async function deletConsultor(codConsultor) {
      animate.value = true
      await consultorDelete(codConsultor).then(() => {
        emit("atualizar-consultores");
        const elementModal = document.getElementById("deletarConsultorModal");
        hideModal(elementModal);
        showTimeAlert("Consultor excluído com sucesso");
      }).catch((e) => {
        showTimeAlert("Não foi possível completar a solicitação", "error");
      });
      animate.value = false
    }

    function deletarConsultor() {
      if (props.consultor) {
        Swal.fire({
          title: "Deseja excluir esse consultor?",
          text: "Essa alteração não poderá ser desfeita!",
          showCancelButton: true,
          confirmButtonText: "Excluir consultor",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-flex background_btn_1_opct text-white",
            cancelButton: "btn btn-light me-3",
          },
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            deletConsultor(props.consultor?.codConsultor);
          }
        });
      }
    }

    return {
      logs,
      animate,
      deletarConsultor
    }
  }
}
